.errormessage {
  color: red;
}

.promo-landing-page-container {
  width: 100%;
}

.promo-landing-page-form {
  display: flex;
  flex-direction: column;
}

.promo-signup-container {
  display: flex;
  align-self: center;
  padding-bottom: 20px;
}

.promo-image-container {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px 40px 20px;
}

.promo-image {
  margin: 0 auto;
  object-fit: cover;
}

#promoImage {
  height: 100%;
  width: 100%;
}

.promo-form-container {
  margin: 10px;
  padding: 40px;
  flex: 3;
  padding: 0px 20px 20px 20px;
}

.message {
  font-size: 16px;
  color: rgb(87, 86, 86);
}

button:disabled,
button[disabled] {
  opacity: 0.3;
  color: black;
}

.signup-button {
  color: white;
  text-align: center;
  padding: 6px 20px;
  background-color: rgb(138, 187, 171);
  border-radius: 4px;
}

/* @font-face {
  font-family: "futura-book";
  src: url("/assets/fonts/futura-book-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

.Center {
  padding-top: 10px;
  padding-bottom: 10px;
}

.Main-text {
  font-size: 30px;
  color: rgb(138, 187, 171);
}

.subtext {
  font-size: 20px;
  text-align: center;
}

.form-container {
  margin-top: 20px;
}

.signup-button:hover {
  color: white;
  cursor: pointer;
  opacity: 1;
}

@media (max-width: 800px) {
  .promo-signup-container {
    flex-direction: column;
  }

  .promo-image-container {
    padding: 0px;
    margin: 10px 10px 10px 10px;
  }

  .promo-image {
    width: 100%;
    padding: 20px 0px 20px 0px;
  }

  #promoImage4 {
    width: 75%;
  }

  .Center {
    margin-top: 0px;
    text-align: center;
  }

  .Main-text {
    font-size: 25px;
    font-style: bold;
  }

  .subtext {
    font-size: 15px;
    text-align: center;
  }
}
