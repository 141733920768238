@media (min-width: 768px) {
  .js-home-button {
    /* height: 4.0263vw; */
    min-width: 14.9092vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

@media (max-width: 767px) {
  .js-home-button {
    height: 11.35265vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.js-home-button {
  border-radius: 4px;
  border: none;
  width: fit-content;
}
