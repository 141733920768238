.white-label-logo {
  margin: auto 0;
  left: 40px;
  width: 120px;
  height: 57px;
  -o-object-fit: contain;
  object-fit: contain;
}

.Center-logo {
  margin: auto 0 auto 0;
}

.Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  height: 71px;
  color: #2c2c2c;
  box-shadow: -3px -1px 18px 6px #928f8f;
  display: flex;
  align-items: center;
}

.StudiosHeading {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  height: 75px;
  color: #2c2c2c;
  box-shadow: -3px -1px 18px 6px #928f8f;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.StudiosHeading img {
  max-width: 400px;
}

.mntstudio-logo {
  padding-top: 3px;
}

.Tabs-container {
  position: absolute;
  right: 15px;
  list-style: none;
  display: flex;
  align-items: baseline;
  text-decoration: none;
  cursor: pointer;
  padding-top: 22px;
}

.Tabs-container li {
  padding-left: 30px;
}

.Tabs-container a {
  text-decoration: none;
  color: #2c2c2c;
}

.Tabs-container > a:hover {
  color: #609a87;
}

.try-for-free-button {
  display: inline-block;
  background-color: rgb(163, 210, 195);
  text-align: center;
  width: 182px;
  padding: 5px;
  border-radius: 4px;
}

/* .Tabs-button{
  text-decoration: none;
  padding: 7px 12px;
  border-radius: 8px;
}
.Tabs-button--outline {
  border: 1px solid #fff;
} */

.js-home-hero-cta-button-link:link,
.js-home-hero-cta-button-link:visited {
  display: inline-block;
  text-decoration: none !important;
  color: #ffffff !important;
  box-shadow: 4px 5px 10px -6px rgba(0, 0, 0, 0.589);
  border: none;
  transition: all 0.3s ease;
}
.js-home-hero-cta-button-link:active,
.js-home-hero-cta-button-link:hover {
  transform: translatey(0.1rem);
  background-color: #7cccb3;
  box-shadow: 3px 4px 11px -5px #000;
}

@media only screen and (max-width: 850px) {
  .Tabs-container li {
    padding-left: 15px !important;
  }
}

@media only screen and (max-width: 800px) {
  .Tabs {
    font-size: 15px;
    display: none;
  }

  .tabs-button {
    padding: 0px;
    font-size: 14px;
    font-weight: 0;
    display: none;
  }

  .try-for-free-button {
    margin-right: 1em;
    width: 10em;
  }

  .StudiosHeading img {
    max-width: 225px;
  }
}

.studios-dropDown-container {
  position: relative;
}

.dropDown {
  font-weight: 100;
  border: none;
  outline: none;
  margin: 0;
}

.dropDown-content {
  display: none;
  position: absolute;
  background-color: rgba(252, 254, 253, 0.68);
  min-width: 165px;
  padding: 5px;
  left: -49px;
  overflow: auto;
  max-height: 80vh;
}

.dropDown-content > a {
  /* padding-bottom: 5px; */
  transition: 0.25s;
  display: block;
  float: none;
  padding: 3px;
  margin-left: 5px;
}

.dropDown-content a:hover {
  color: #2c2c2c;
  transition: 0.25s;
}

.dropDown:hover .dropDown-content {
  display: block;
  list-style: none;
}

.dropDownButton {
  border: none;
  font-size: 1em;

  font-weight: 400;
  background-color: transparent;
  text-align: left;
  list-style-type: none;
}

.dropDown:hover .dropDownButton {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  /*Safari*/
  text-decoration: none;
}

.dropDown-content a:hover {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  /*Safari*/
  text-decoration: none;
}

.dropDown-content::-webkit-scrollbar {
  width: 4px;
}

.dropDown-content::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 2px;
}

.dropDown-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  list-style-type: none;
}

.arrowDown {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  padding: 3px;
  list-style-type: none;
}

/* .open-slide svg {
  position: absolute;
  top: 0;
  left: 9px;
  fill: grey;
} */
@media only screen and (max-width: 465px) {
  .StudiosHeading img {
    max-width: 145px;
  }
}

@media only screen and (max-width: 400px) {
  .white-label-logo {
    left: 5px;
  }
  .StudiosHeading img {
    max-width: 135px;
  }
}

@media only screen and (max-width: 370px) {
  .white-label-logo {
    width: 100px;
  }
  .StudiosHeading img {
    max-width: 125px;
  }
}
