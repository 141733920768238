.image-container {
  width: 100%;
  overflow: hidden;
  display: grid;
  /* background-image: url(/static/media/workplace-wellness-firstPic.9bf1634f.png); */
  height: 631px;
  background-size: cover;
}

.first-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-main {
  text-align: center; /* Center text horizontally */
  color: white;
  z-index: 1;
  margin-top: 60px;
  width: 90%;
}

.main-title {
  align-self: stretch;
  font-family: "Raleway";
  font-size: 51.776px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
}

.first-paragraph {
  width: 100%;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding: 0px 20px 40px 20px;
}

.first-button {
  background-color: transparent;
  border: 2px solid white; /* Set the border color to white */
  border-radius: 8px; /* Adjust the radius for rounded corners */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Adjust padding as needed */
}

.two-columns {
  display: flex;
  height: 380px;
  align-items: center;
  justify-content: space-between; /* Space between the columns */
  padding: 60px 100px;
  gap: 60px;
}

.who-we-serve,
.hr-employers {
  display: flex;
  flex-direction: column; /* Add this to stack content vertically */
  justify-content: center;
  height: 210px;
  margin-top: 10px;
  /* Adjust padding as needed */
  border: 1px solid white; /* Add borders for separation */
}

.who-we-serve-header {
  color: #000;
  font-family: "Raleway";
  font-size: 2em;
  font-weight: 700;
}

.who-we-serve-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 1em;
  font-weight: 400;
  line-height: 175%;
  width: 100%;
  margin-left: 0 !important;
}

.hr-column {
  display: flex;
  height: 210px;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.hr-image {
  width: 40%;
  height: 100%;
  object-fit: cover;
}

.hr-text {
  border: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.hr-header {
  color: #000;
  font-family: Raleway;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hr-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  margin-left: 0 !important;
}

/* .three-column{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 20px;
  } */

.business-need-column {
  padding: 20px; /* Adjust padding as needed */
  border: 1px solid white; /* Add borders for separation */
  background-color: white; /* Background color for columns */
}

.business-needs {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 100px 100px 80px 100px;
  height: 450px;

  > div > h2 {
    text-align: left;
  }
}

.business-header {
  color: #000;
  font-family: "Raleway";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.business-body {
  width: 700px;
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.three-columns {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.three-columns-business {
  display: flex;
  justify-content: space-between;
}

.business-header {
  color: #000;
  text-align: center;
  font-family: "Raleway";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.business-body {
  width: 700px;
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.business-column {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.business-column-header {
  padding: 20px 20px;

  > h2 {
    color: #000;
    font-family: "Raleway";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.business-column-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 90%;
  text-align: left;
}

.business-column > .business-column-body {
  margin-left: 0 !important;
}

.business-icon-and-header {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 5em;

  > img {
    width: 62px;
  }
}

.overlapping-percentage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empower-text {
  flex: 1;
  position: relative;
  display: flex;
}

.percentage-text {
  position: absolute;
  font-size: 36px;
  font-weight: 700;
}

.percentage-first-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.percent-second-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.empower-background {
  background-color: #f5f8f7;
  padding: 60px 100px;
  height: 450px;
}

.empower-paragraph {
  position: relative;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.empower-main-p {
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.empower-header {
  color: #000;
  font-family: "Raleway";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ignite-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0 60px;

  overflow: hidden;
}

.ignite-text {
  display: flex;
  flex-direction: column;
  height: 340px;
  justify-content: space-between;
  padding-right: 10px;
}

.ignite-header {
  color: #000;
  font-family: "Raleway";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ignite-paragraph {
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.second-button {
  background-color: black;
  border: 2px solid black;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
}

.ignite-image {
  flex: 1; /* Allows image to fill the space in flex container */
  max-width: 150%; /* Ensures the image does not take more than half of the container */
  height: 150%; /* Keeps the image height proportional to its width */
  object-fit: cover; /* Ensures the image covers the area of the element, might crop */
}

/* Modal Section */
.workplace-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  justify-content: center;
}

.modal-main {
  background-color: white;
  padding: 20px 0 20px;
  border-radius: 5px;
  width: 600px;
  max-height: 70vh;
  overflow: scroll;
}

.health-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  > h3 {
    text-align: center;
    margin: 0;
    width: 100%;
    font-weight: 700;
    font-family: "Raleway";
  }
  > p {
    width: 100%;
    margin: 0;
    text-align: center;
    font-family: "Raleway";
    font-size: 1.2em;
  }
}

.health-form {
  padding: 10%;
  margin: 0;
  width: 100%;
}

.hs_error_rollup {
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
.hs-error-msgs {
  padding: 0;
  margin: 0;
}
.health-form .input {
  margin-right: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 20px;
}
.health-form .hs-input {
  width: 100% !important;
}
.health-form .form-columns-2 {
  display: flex;
  gap: 80px;
}
.health-form .form-columns-1 {
  width: 100%;
}
.hs-fieldtype-text {
  height: auto;
}

.health-form .hs-form-field {
  border: none;
  margin-bottom: 10px;

  > label {
    font-weight: bold;
  }
}

.health-form .field .hs-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.health-form .form-columns-2 input {
  width: 90%;
}

.hs-message {
  height: 100%;
  margin: 0px;
  padding: 0;
}

.hs-message .input .hs-fieldtype-textarea {
  height: 150px;
}
.health-form .hs-button {
  background-color: black;
  width: 120px;
  height: 40px;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.hs-error-msgs {
  width: 90%;
  > li {
    list-style-type: none;
  }
}

.close-modal-button {
  position: absolute;
  right: 0;
  margin-right: 40px;
  border: none;
  background-color: transparent;
  z-index: 4;
}

body.workplace-modal-open {
  overflow: hidden;
}

.hs-submit {
  padding-top: 20px;
}

/* tablet version */
@media (max-width: 1100px) {
  .main-title {
    font-size: 3em;
  }

  .first-paragraph {
    font-size: 1.2em;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 40px;
  }

  .two-columns {
    gap: 20px;
    padding: 20px 40px;
  }

  .hr-text {
    padding: 20px 10px;
  }

  .business-needs {
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .empower-background {
    padding: 60px 40px;
    height: 100%;
  }

  .ignite-container {
    padding: 0px 0 0px 40px;
    height: 100%;
  }
  .ignite-text {
    padding: 20px 0;
    height: 100%;
  }
  .ignite-header {
    margin-bottom: 60px;
  }
  .ignite-paragraph {
    margin-bottom: 60px;
  }

  /* Modal section */
}

/* mobile version */
@media (max-width: 625px) {
  body {
    padding: 0;
    margin: 0;
  }

  .overlay-main {
    width: 100%;
  }

  .image-container {
    background-position: center;
  }

  .main-title {
    font-size: 2em;
    margin-bottom: 40px;
  }

  .first-img {
    width: calc(100% + 100px);
    height: 100%;
    object-fit: cover;
    object-position: -85px center;
  }

  .first-paragraph {
    line-height: 24px;
    font-size: 1em;
  }

  .two-columns {
    flex-direction: column;
    padding: 40px 20px;
    height: 700px;
  }

  .who-we-serve,
  .hr-employers {
    flex: 0;
    font-size: 16px;
  }

  .hr-column {
    flex-direction: column;
    height: 420px;
  }
  .hr-image {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }
  .hr-text {
    padding: 20px 32px;
    font-size: 16px;
  }

  .business-needs {
    padding: 30px 20px;
    height: auto;
  }
  .three-columns {
    flex-direction: column;
    padding: 40px 0;
  }

  .business-column {
    padding: 20px 0;
  }

  .business-header {
    padding: 20px 0 40px 0;
    font-size: 24px;
  }
  .business-body {
    line-height: 30px;
  }
  .business-icon-and-header {
    padding-left: 40px;
  }
  .business-column-header {
    padding-left: 40px;
  }

  .three-columns-business {
    flex-direction: column;
  }

  .empower-background {
    padding: 30px 20px;
    height: 100%;
  }
  .empower-header {
    font-size: 24px;
  }
  .empower-main-p {
    margin-left: 0 !important;
    margin: 20px 0;
    font-size: 16px;
  }

  .ignite-container {
    flex-direction: column;
    padding: 0 !important;
    height: 100%;
    padding-bottom: 80px;
  }

  .ignite-image {
    order: 1;
    height: 340px;
    object-fit: cover;
    object-position: 0% 70%;
  }

  .ignite-text {
    order: 2;
    height: 240px;
    padding: 40px 20px 0 20px !important;
  }
  .ignite-header {
    font-size: 24px;
    margin: 0;
  }
  .ignite-paragraph {
    font-size: 16px;
    margin: 0;
  }

  /* Modal Section */
  .workplace-modal {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    max-height: 80vh;
  }
  .health-form {
    padding: 10px;
    margin: 0;
    width: 100%;
  }
  .health-modal-header {
    padding: 20px 10px;
    margin-bottom: 10px;
    > h3 {
      font-size: 1.5em;
    }
    > p {
      font-size: 1em;
    }
  }
  .health-form .form-columns-2 {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 1;
  }

  .health-form .form-columns-1 .hs-message {
    margin: 0px !important;
    padding: 200px;
  }
  .hs-form-private {
    width: 90%;
  }

  .hs-error-msgs {
    width: 90%;
    > li {
      font-size: 0.8em;
      list-style-type: none;
    }
  }
  .hs-message .input .hs-fieldtype-textarea {
    height: 100px;
  }
  .close-modal-button {
    margin-right: 20px;
  }
}

@media (max-height: 800px) {
  .workplace-modal {
    margin-top: 40px;
  }
}
