.header-container {
  height: 701px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-left: 97px;
  padding-right: 0px;
}

.text-container {
  display: flex;
  width: 640px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 38px;
  font-family: "Raleway", sans-serif;
}

.text-container h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-container h2 {
  color: #ee8373;
  font-family: "Raleway", sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.46px;
}

.text-container p {
  color: #5c5959;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
  margin-left: -2px;
}

.text-container button {
  border: none;
  border-radius: 27.5px;
  padding: 14px 20px;
  background: #ee8373;
  color: #fff;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  border: 2px solid #ee8373;

  &:hover {
    background-color: white;
    color: #ee8373;
    border: 2px solid #ee8373;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }
}

.image-container {
}

.feature-container {
  width: 100%;
  /* height: 200px; this will make the div appear smaller which is probably what they mean by padding 
  since this has no padding*/
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 65px;
}

.feature-container p {
  color: #000;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.scroll-container {
  width: 100%;
  animation: scroll-animation 20s linear infinite;
}

@keyframes scroll-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.transition-container {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.transition-text-container-header {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 38px;
  z-index: 2;
}

.transition-text-container-header h3 {
  color: #fff;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 111px; /* 308.333% */
}

.transition-text-container-header h1 {
  width: 985px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 92px; /* 143.75% */
  letter-spacing: -1.28px;
}

.meeting-img {
  width: 100%;
  transition: filter 0.5s ease-in;
  filter: brightness(1);
  z-index: 1;
}

.meeting-img {
  filter: brightness(0.5);
  transition-delay: 0.2s;
}

.transition-text-container-header {
  transition-delay: 200ms;
  opacity: 1;
}

/* Modal Section */
.workplace-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  justify-content: center;
}

.modal-main {
  padding: 20px 0 20px;
  border-radius: 5px;
  width: 600px;
  max-height: 70vh;
  overflow: scroll;
}

.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  > h3 {
    text-align: center;
    margin: 0;
    width: 100%;
    font-weight: 700;
    font-family: "Raleway";
  }
  > p {
    width: 100%;
    margin: 0;
    text-align: center;
    font-family: "Raleway";
    font-size: 1.2em;
  }
}

.hubspot-form {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hs-phone {
  height: 100%;
}

.hs_error_rollup {
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
.hs-error-msgs {
  padding: 0;
  margin: 0;
}
.hubspot-form .input {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.hubspot-form .hs-input {
  width: 100% !important;
}
.hubspot-form .form-columns-2 {
  display: flex;
  gap: 20px;
}
.hubspot-form .form-columns-1 {
  width: 100%;
}
.hs-fieldtype-text {
  height: auto;
}

.hubspot-form .hs-form-field {
  border: none;
  margin-bottom: 10px;

  > label {
    font-weight: bold;
  }
}

.hubspot-form .field .hs-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.hubspot-form .form-columns-2 input {
  width: 90%;
}

.hs-message {
  height: 100%;
  margin: 40px 0 0 0 !important;
  padding: 0;
}

.hs-message .input .hs-fieldtype-textarea {
  height: 150px;
}
.hubspot-form .hs-button {
  background-color: black;
  width: 120px;
  height: 40px;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.hs-error-msgs {
  width: 90%;
  > li {
    list-style-type: none;
  }
}

.close-modal-button {
  position: absolute;
  right: 0;
  margin-right: 40px;
  border: none;
  background-color: transparent;
  z-index: 4;
}

body.workplace-modal-open {
  overflow: hidden;
}

.hs-submit {
  padding-top: 20px;
}

/* Tablet Version */
@media (max-width: 1100px) {
}
/* End of Tablet Version */

/* Mobile Version */
@media (max-width: 625px) {
  .header-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    padding: 0;
    padding-bottom: 100px;
  }

  .image-container {
    width: 100%;
    height: 100%;
    padding: 40px 0 40px 20px;

    > img {
      width: 100%;
      height: 100%;
      padding: 80px 0 40px 20px;
      object-fit: contain;
    }
  }
  .text-container {
    padding: 0;
    width: 100%;
    gap: 14px;
    padding: 0 20px;
    > div h1 {
      font-size: 40px;
    }

    > div h2 {
      font-size: 28px;
    }

    > div p {
      font-size: 12px;
    }

    > button {
      font-size: 18px;
    }
  }

  .header-mid-section {
    display: flex;
    flex-direction: column-reverse;
  }

  .transition-container {
    width: 100%;
    height: 600px !important;
  }

  .transition-text-container-header {
    padding: 60px 20px;

    > h3 {
      font-size: 28px;
      width: 100%;
      line-height: 60px;
      text-align: left;
    }
    > h1 {
      font-size: 46px;
      width: 100%;
      line-height: 70px;
      text-align: left;
    }
  }

  .meeting-img {
    height: 100%;
    object-fit: cover;
  }

  .feature-container {
    height: 200px;
    /* margin: 40px 0; */
  }
}
/* End of Mobile Version */
