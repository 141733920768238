.rituwell-container {
  width: 100%;
  height: 100%;
}
.rituwell-section {
  width: 100%;
  position: relative;
  height: 100vh;
}

.rituwell-background {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.rituwell-intro {
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 40px;
  padding: 0 40px;
  margin: 40px 0;
}

.rituwell-mid-section {
  position: absolute;
  z-index: 2;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h1 {
    text-align: center;
    color: black;
    margin: 40px 0;
    font-family: "raleway", sans-serif;
    letter-spacing: 2px;
    font-weight: 600;
    color: white;
    line-height: 65px;
    font-size: 40px;
  }
}

.rituwell-white {
  background-color: transparent;
  padding: 0 20px;
  width: 40%;
}

.rituwell-demo-button {
  font-weight: 100 !important;
  background-color: #f18c7f;
  color: white;
  border-radius: 4px;
  border: none;
  transition: all 0.3s ease;
  padding: 14px 20px;
  text-align: center;
  font-family: "Coco Gothic";

  &:hover {
    color: white;
    text-decoration: none;
    transform: translatey(0.4rem);
    background-color: #ef614c;
    box-shadow: 3px 4px 11px -5px #000;
  }
}
@media (max-width: 500px) {
  .rituwell-intro {
    font-size: 26px;
  }

  .rituwell-section {
    height: 640px;
    width: 100%;
    margin-bottom: 40px;
  }

  .rituwell-mid-section {
    > h1 {
      line-height: 40px;
      padding: 0 40px;
      font-size: 24px;
    }
  }

  .rituwell-white {
    width: 70%;
  }
  .rituwell-background {
    height: 100%;
    width: 100%;
    margin: auto;
  }
}
