.promo-submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .promo-submit-container {
  }
}
