/* @font-face {
  font-family: "futura-book";
  src: url("/assets/fonts/futura-book-font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500,600;700");

.errormessage {
  color: red;
}

.message {
  font-size: 16px;
  color: rgb(87, 86, 86);
}

.signup {
  position: relative;
  display: flex;
  margin-top: 72px;
  min-height: 900px;
}

.signup-left {
  width: 55%;
  position: absolute;
  left: 0;
}
.signup-right {
  width: 45%;
  position: absolute;
  right: 0;
  /* max-width: 600px; */
  display: flex;
  justify-content: center;
  min-height: 900px;
  overflow: hidden;
}

.signup-header {
  text-align: left;
  padding: 2rem 0 0 10%;
}

.signup-header .Main-text {
  text-align: left;
  color: black;
  margin: 2rem 0;
  font-family: raleway;
  font-size: 1.5rem;
}

.signup-left form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: futura-book;
  width: 100%;
  padding: 0 10%;
  margin: 2rem 0;
}

.signup-left input {
  margin: 0 0 10px 0;
  max-width: 500px;
  width: 90%;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  font-family: "Raleway";
}
.signup-left input::placeholder {
  color: #a4a4a4;
}

.signup-left input[type="checkbox"] {
  width: auto;
  margin: 0 10px;
}

.signup-left label {
  font-family: "Raleway";
  margin-bottom: 0.25rem;
}

.promotion-checkbox {
  display: flex;
  font-size: 0.75rem;
  margin-top: 3rem;
}

.signup-left p {
  margin: 5px;
  font-family: "Raleway";
}

.signup-subtext {
  margin-bottom: 200px;
  margin-left: 10%;
  font-size: 1.2rem;
  max-width: 65%;
}
.signup-subtext > p:nth-child(2) {
  font-size: 20px;
}
.signup-subtext a {
  /* font-size: 1.2rem; */
  /* color: black; */
  font-weight: 700;
}
.signup-subtext p:first-child {
  font-size: 0.75rem;
}
.signup-subtext p:first-child a {
  font-size: 0.75rem;
}

.Center {
  padding-top: 10px;
  padding-bottom: 10px;
}

.signup-left img {
  max-width: 200px;
}

.signup-featureImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.4);
}
.signup-studioLogos {
  width: 100%;
  max-width: 550px;
}

.signup-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: absolute;
  padding-left: 20px;
  min-height: 100vh;
  justify-content: flex-start;
  max-width: 600px;
}
.signup-scheduleDemo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-scheduleDemo > h1 {
  margin: 50px 2.7% 2.7%;
  font-size: 1.9vw;
  color: white;
}

.signup-partners {
  color: white;
  width: 98%;
  height: 38rem;
  /* max-width: 40vw; */
}

.signup-partners p {
  font-size: 1.8rem;
  text-align: center;
  margin: 2rem 0;
}

.signup-button:disabled,
button[disabled] {
  opacity: 0.5;
  color: white;
  cursor: no-drop;
}

.signup-button,
.schedule-button,
.signup-btn {
  color: white !important;
  text-align: center;
  padding: 6px 20px;
  background-color: rgba(163, 210, 195, 0.8);
  border-radius: 4px;
}

.signup-btn,
.schedule-button {
  color: white;
  background-color: #9ac8b9;
  padding: 10px 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 90%;
}

.signup-btn {
  margin-top: 3rem;
}

.schedule-button {
  width: 200px;
}

@media only screen and (min-width: 2000px) and (max-height: 900px) {
  .signup {
    height: calc(100vh + 400px);
  }
}

@media only screen and (min-width: 1500px) {
  .signup-right {
    left: 50vw;
  }
  .signup-left {
    right: 55vw;
    left: auto;
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .signup {
    flex-direction: column;
  }
  .signup-left {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
  }
  .signup-header {
    padding: 0;
    margin: auto;
  }
  .signup-left form {
    margin: 0 0 1rem;
  }
  .signup-left input {
    width: 100%;
  }
  .signup-btn {
    width: 100%;
    margin-top: 1.5rem;
  }
  .signup-subtext {
    margin: auto;
    margin-bottom: 2rem;
    max-width: 80%;
    font-size: 1.4rem;
  }
  .signup-subtext a {
    padding: 0;
    font-size: 1.4rem;
  }
  .signup-subtext a::before {
    content: " ";
  }
  .signup-right {
    display: flex;
    position: relative;
    width: 100%;
    height: 90vh;
  }
  .signup-overlay {
    padding-left: 0;
    justify-content: flex-start;
    width: 100%;
  }
  .signup-scheduleDemo > p {
    font-size: 1.2rem;
  }
  .signup-scheduleDemo > p {
    margin: 19px 2.7% 2.7%;
  }
  .signup-studioLogos-container {
    overflow: scroll;
  }
  .signup-studioLogos-container::-webkit-scrollbar {
    display: none;
  }
  .signup-studioLogos {
    transform: scale(2) translateY(30%);
    margin-left: 20%;
  }
  .signup-partners {
    bottom: 0.5rem;
    margin-top: 1rem;
    padding-right: 25px;
  }
  .signup-partners p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .signup-featureImg {
    max-width: 100vw;
  }
}
/* login */
@media only screen and (min-height: 630px) {
  .footer-login {
    bottom: 0;
    width: 100%;
    position: fixed;
  }
}

/* @media only screen and (max-width: 850px) and (min-height: 800px) {
  .footer-login-original {
    bottom: 0;
    width: 100%;
    position: fixed;
    height: 330px;
  }
} */

@media only screen and (min-width: 850px) and (min-height: 730px) {
  .footer-login-original {
    bottom: 0;
    width: 100%;
    position: fixed;
  }
}

/* Surface Pro 7 */
@media only screen and (min-height: 1200px) {
  .signup {
    min-height: 1100px !important;
  }
  .signup-left {
    min-height: 100vh !important;
    margin-top: 100px;
  }
  .signup-right {
    min-height: 100vh !important;
  }
  .signup-overlay {
    margin-top: 100px;
  }
  .signup-featureImg {
    min-height: 100vh !important;
  }
}
