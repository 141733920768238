@media (min-width: 768px) {
  .js-font-65 {
    font-size: 4.7584186vw;
  }

  .js-font-40 {
    font-size: 2.9282576vw;
  }

  .js-font-30 {
    font-size: 2.19619vw;
  }

  .js-font-25 {
    font-size: 1.830161vw;
  }

  .js-font-24 {
    font-size: 1.75695vw;
  }

  .js-font-20 {
    font-size: 1.46412vw;
  }

  .js-font-18 {
    font-size: 1.3177vw;
  }

  .js-font-16 {
    font-size: 1.1713vw;
  }

  .js-font-15 {
    font-size: 1.04166vw;
  }

  .js-font-14 {
    font-size: 1.02489vw;
  }

  .js-font-12 {
    font-size: 1.02489vw;
  }

  .js-font-6 {
    font-size: 0.4166vw;
  }
}

@media (max-width: 767px) {
  .js-home-why-cta-description {
    padding-left: 10.628vw;
    padding-right: 10.628vw;
  }

  .js-font-40-mb {
    font-size: 9.6618vw;
  }

  .js-font-30-mb {
    font-size: 7.24637vw;
  }

  .js-font-25-mb {
    font-size: 6.0386vw;
  }

  .js-font-24-mb {
    font-size: 5.79715vw;
  }

  .js-font-20-mb {
    font-size: 4.83091vw;
  }

  .js-font-18-mb {
    font-size: 4.347826vw;
  }

  .js-font-15-mb {
    font-size: 3.62318vw;
  }

  .js-font-14-mb {
    font-size: 3.3816vw;
  }

  .js-font-12-mb {
    font-size: 2.89855vw;
  }
}

a.js-font-white:hover {
  text-decoration: none;
}

.js-open-sans {
  font-family: "Open Sans";
}

.js-avenir-next {
  font-family: "Open Sans";
}

.js-raleway {
  font-family: "Raleway";
}

.js-font-black {
  color: #000000;
}

.js-font-semibold {
  font-weight: 600;
}

.js-font-medium {
  font-weight: 500;
}

.js-font-EE8373 {
  color: #ee8373;
}

.js-font-8A8A8A {
  color: #8a8a8a;
}

.js-font-9AC8B9 {
  color: #9ac8b9;
}

.js-font-1F3830 {
  color: #1f3830;
}

.js-font-818181 {
  color: #818181;
}

.js-font-000000 {
  color: #000000;
}

.js-font-949494 {
  color: #949494;
}

a.js-font-white,
.js-font-white,
.js-font-white:hover {
  color: #ffffff !important;
}

.js-text-align-center {
  text-align: center;
}

a.js-font-3D3D3D,
.js-font-3D3D3D,
.js-font-3D3D3D:hover {
  color: #3d3d3d;
}

a.js-font-333333,
.js-font-333333,
.js-font-333333:hover {
  color: #333333;
}

a.js-font-333333 {
  text-decoration: none;
}

.js-font-italic {
  font-style: italic;
}

@media (max-width: 812px) and (max-height: 414px) {
  .js-font-30-mb {
    font-size: 7.24637vh;
    height: 100vh;
  }
}
