.live-schedule-header {
  height: 51px;
  top: -192px;

  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 55px;

  text-align: center;
  letter-spacing: 3px;

  color: #ffffff;
}

.live-schedule-try-free-button {
  box-shadow: none;
  padding: 5px;
  width: 216px;
  height: 41px;
  font-size: 21px;
  font-weight: bold;
  font-family: "Coco Gothic";
  margin: 20px;
  margin-bottom: 41px;
}

.live-schedule-info {
  height: 73px;
  top: -188px;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 25.2816px;
  line-height: 150%;

  text-align: center;

  color: #ffffff;
}
button:active {
  outline: none;
}

.hover:hover {
  cursor: pointer;
}

.exit-modal {
  width: 250px;
  height: 100%;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
}

.exit-modal-button1 {
  color: #9ac8b9;
  background-color: white;
  border: 1px solid #9ac8b9;
  border-radius: 4px;
  padding: 7px 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.exit-modal-button2 {
  color: white;
  background-color: #9ac8b9;
  padding: 7px 30px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  border: none;
}

.payment-columns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5px;
}

.payment-columns-1 {
  display: flex;
  flex-direction: column;
}
.payment-columns-2 {
  display: flex;
  flex-direction: column;
}

.payment-columns-1 > h3 {
  font-size: 17px;
  font-weight: 700;
  color: #c4c4c4;
  white-space: nowrap;
  text-align: right;
}
.payment-columns-1 > h3:last-child {
  margin-top: 20px;
}

.payment-columns-2 > h3 {
  font-size: 17px;
  font-weight: 400;
}
.payment-columns-2 > h3:last-child {
  margin-top: 20px;
}

.confirm-button {
  padding: 10px 28px;
  background-color: #9ac8b9;
  border-radius: 4px;
  color: white;
  width: 200px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}

.confirm-button:hover {
  cursor: pointer;
}
.order-summary-text {
  font-weight: 400;
  font-size: 21px;
  text-align: center;
  margin: 0 auto;
}

.order-summary-details {
  font-weight: 400;
  font-size: 17px;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

.login-modal {
  display: flex;
  flex-direction: column;
}

.login-modal > input {
  margin: 10px auto;
  width: 80%;
}

.live-schedule-container-content {
  height: fit-content !important;
}

@media only screen and (max-width: 1200px) {
  .live-schedule-img-container {
    height: 450px !important;
  }
  .live-schedule-img {
    height: 450px !important;
    margin-top: 0 !important;
  }

  .live-schedule-header {
    font-size: 35px;
  }
  .live-schedule-info {
    font-size: 25px;
  }
  /* div.container-content > div.card-top-bar{
    display: none;
  }
  div.container-content > hr:first-of-type{
    display: none;
  } */
}

@media only screen and (max-width: 500px) {
  .live-schedule-header {
    font-size: 25px;
  }
  .live-schedule-info {
    font-size: 20px;
  }
  .live-schedule-container-content {
    height: fit-content !important;
  }
  /* div.container-content > hr{
    display: none;
  } */

  div.live-schedule-recent-classes {
    padding: 0px !important;
  }
  div.live-schedule-recent-classes > div.container-content {
    padding: 10px;
  }
  .calendar-top {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
    width: 90vw !important;
    margin: 10px !important;
  }
  div.this-month {
    padding: 0px !important;
    display: block !important;
    color: #9597a1;
  }
  div.this-month > h1 {
    font-family: Fjalla One !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 20px !important;
    text-align: center;
    padding-top: 10px;
  }
  div.this-month > button {
    display: none;
  }

  div.this-week-week {
    padding-bottom: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    text-align: center;
  }
  button.back-arrow-live-button {
    transform: translateY(-56px);
  }

  .results-container {
    padding: 0px !important;
    margin-top: 26px;
    min-height: 350px;
  }

  .results-top {
    display: block !important;
  }

  .results-header-container {
    display: flex;
    margin-bottom: 19px;
  }
  .results-header {
    font-size: 16.1214px !important;
    padding-left: 9px !important;
    padding-right: 0px !important;
  }

  .results-header-border {
    height: 15px !important;
    margin-top: 14px !important;
  }
  .results {
    font-size: 16px !important;
    padding: 2px !important;
    padding-left: 13px !important;
    border-left: 1px solid red;
    line-height: normal !important;
    margin-top: 0px !important;
    margin-left: 20px !important;
  }
  .results-bottom-container > hr {
    display: none;
  }
  .live-schedule-try-free-button {
    box-shadow: none;
    padding: 9px !important;
    width: 200px !important;
    height: 35px !important;
    font-size: 14px !important;
    font-weight: bold;
    font-family: "Coco Gothic";
    margin: 0px !important;
  }

  .live-schedule-title-instructor {
    font-size: 13px;
  }
  .upcoming-livestream-classes-list-item-left > * {
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .live-stream-schedule-date-wrapper {
    width: 3.2em !important;
    height: 3.2em !important;
  }
}

.nav-tabs {
  margin-bottom: 20px;
  display: flex;
}

.nav-item {
  font-weight: 800;
  padding: 12px;
  cursor: pointer;
  font-size: 18px;
}

.nav-item:hover {
  border-bottom: 2px solid black;
}

.nav-tabs .active {
  border-bottom: 2px solid black;
}
