.Button-cancel {
  padding: 20px 30px 20px 30px;
  background-color: red;
  border: none;
  font-size: 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: sans-serif;
}

.Button-cancel:hover {
  opacity: 0.7;
}

.Cancel-subscription {
  text-align: center;
  margin: 50px;
}

.Cancel-text {
  text-align: center;
  font-size: 25px;
}

.Cancel-container {
  display: flex;
  width: 700px;
  margin: 0 auto 0 auto;
}

.Active {
  border: 3px solid #9ac8b9;
}

.Z-index10 {
  z-index: 10;
}

.Responsive-plan {
  padding: 0 6px;
  float: left;
  width: 50%;
}

.Push-down {
  margin-top: 20%;
}

.cancelspace {
  margin-top: 30%;
}

@media only screen and (max-width: 800px) {
  .Responsive-plan {
    width: 50%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 700px) {
  .Responsive-plan {
    width: 100%;
  }
}
