.ReactModal__Content.ReactModal__Content--after-open.day-modal,
.ReactModal__Content.ReactModal__Content--after-open.schedule-new {
  top: 15%;
  left: 30%;
  right: auto;
  bottom: auto;
  width: 35%;
  /* min-width: 400px; */
  height: 40%;
  min-height: 250px;
  background-color: white;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: linear-gradient(#89cebb 5%, white 50%);
  z-index: 99;
}

.day-modal {
  height: 50% !important;
}

.schedule-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-section {
  margin: 0 0;
  padding: 0 0;
}

.date-section select {
  margin: 5px;
  color: black;
  border: 2px solid rgba(196, 196, 196, 0.7);
  border-radius: 7px;
  /* width: 94px; */
  padding: 3px 0px;
}

.reschedule-modal-content {
  height: 100%;
}

.reschedule-modal-content > * {
  z-index: 99;
}
.ReactModal__Content > .reschedule-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.reschedule-modal-content > .form,
.delete-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.delete-form {
  height: 100%;
  justify-content: space-evenly;
}

.reschedule-modal-content > h2 {
  padding-top: 30px;
  color: white;
}

.reschedule-modal-content > div:nth-child(2) {
  padding-bottom: 30px;
  color: white;
}

.reschedule-modal-content > .form > div:first-child {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.reschedule-modal-content > * input {
  width: 44%;
  text-align: center;
}

.reschedule-modal-content > * select {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 72px;
  text-align: center;
}

#status {
  text-align: center;
  border: 1px solid gray;
  margin-top: 0px;
  margin-bottom: 16px;
  width: 100px;
}

.reschedule-modal-content > * .submit {
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #9ac8b9;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 25px;
}

@media only screen and (max-width: 800px) {
  .modal-info,
  .day-modal,
  .schedule-program-modal {
    min-width: 300px !important;
  }
  .schedule-workout-modal-title {
    font-size: 22.5px;
  }
}

@media only screen and (max-width: 500px) {
  .modal-info,
  .day-modal,
  .schedule-program-modal {
    min-width: 250px !important;
  }
  .schedule-workout-modal-title {
    font-size: 16px;
  }
  .schedule-workout-modal-subtitle {
    font-size: 14px;
    text-align: center;
  }
  .reschedule-modal-content > * select {
    width: 50px;
    font-size: 10px;
  }
}
