@mixin respond($breakpoint) {
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 23.4375em) {
      @content;
    } //400px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
}
.account {
  &__box {
    display: flex;
    height: 100%;
    @include respond(phone) {
      display: block;
      height: 100vh;
    }
    &--1 {
      width: 50%;
      background-image: url("../../images/account2.jpg");
      background-size: cover;
      background-position: center;
      @include respond(phone) {
        height: 40%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 155%;
        background-position-x: inherit;
      }
    }
    &--2 {
      padding: 2rem 4rem;
      width: 50%;
      height: 100%;
      position: relative;
      margin-top: 4rem;
      @include respond(tab-land) {
        padding: 1rem 2rem;
      }
      @include respond(tab-port) {
        padding: 0.5rem 1rem;
      }
      @include respond(phone) {
        width: 100%;
        height: 60%;
        margin-top: 0.2rem;
      }
    }
    &--3 {
      width: 50%;
      min-height: 100vh;
      padding: 2rem 4rem;
      position: relative;
      @include respond(tab-land) {
        padding: 1rem 2rem;
      }
      @include respond(tab-port) {
        padding: 0.5rem 1rem;
      }
      @include respond(phone) {
        width: 100%;
        min-height: 46%;

        margin-top: 0.2rem;
      }
    }
  }
  &__header {
    @include respond(phone) {
      position: absolute;
      top: -6rem;
      left: 1.5rem;
      color: white;
    }
    &--text {
      letter-spacing: 1px;
      @include respond(tab-land) {
        font-size: 2rem !important;
      }
      @include respond(tab-port) {
        font-size: 1.3rem !important;
      }
      @include respond(tab-port) {
        letter-spacing: 0;
      }
    }
  }
}
.check__circle {
  &--box {
    width: 7rem;
    height: 7rem;
    margin: 0 auto;

    @include respond(tab-land) {
      width: 5rem;
      height: 5rem;
    }
    @include respond(tab-port) {
      margin-top: 1rem;
      width: 3.7rem;
      height: 3.7rem;
    }
    @include respond(phone) {
      position: absolute;
      top: -11rem;
      border-radius: 50%;
      background-color: white;
    }
  }
  &--box-whitelabel {
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    margin-top: 16rem;

    @include respond(tab-land) {
      width: 7rem;
      height: 7rem;
    }
    @include respond(tab-port) {
      width: 5rem;
      height: 5rem;
    }
    @include respond(phone) {
      margin-top: 10rem;
      margin-left: 3rem;
    }
    @include respond(small-phone) {
      margin-top: 6rem;
      line-height: 1.4;
    }
  }
  &--icon {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
    color: rgb(163, 210, 195);
  }
  &--whiteLabel {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
    color: white;
  }
}

.margin__top {
  &--1 {
    margin-top: 1rem !important;
    @include respond(phone) {
      margin-top: 0.4rem !important;
    }
  }
  &--2 {
    margin-top: 2rem !important;
    @include respond(phone) {
      margin-top: 1rem !important;
    }
  }
  &--3 {
    margin-top: 3rem !important;
    @include respond(phone) {
      margin-top: 1.4rem !important;
    }
  }
  &--4 {
    margin-top: 4rem !important;

    @include respond(phone) {
      margin-top: 1rem !important;
    }
  }
  &--big {
    margin-top: 12rem !important;

    @include respond(phone) {
      margin-top: 5rem !important;
    }
  }
}
.font--size-big {
  font-size: 3rem;
  @include respond(tab-land) {
    font-size: 2rem !important;
  }
  @include respond(tab-port) {
    font-size: 1.9rem !important;
  }
  @include respond(phone) {
    font-size: 1.7rem !important;
  }
}
.font--size-1 {
  @include respond(tab-land) {
    font-size: 1.4rem !important;
  }
  @include respond(phone) {
    font-size: 1.3rem !important;
  }
}

.font--size-2 {
  @include respond(tab-land) {
    font-size: 1.2rem !important;
  }
  @include respond(phone) {
    font-size: 1.1rem !important;
  }
}
.font--size-3 {
  @include respond(tab-port) {
    font-size: 0.9rem !important;
  }
  @include respond(phone) {
    font-size: 1rem !important;
  }
}
.font--weight {
  font-weight: 700 !important;
}

.paragraph--size {
  font-size: 1.21rem;
  letter-spacing: 1px;
}
.stores {
  &--text {
    text-align: center;
  }
  &--box {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    &--image {
      width: 90%;
      height: auto;
    }
  }
}
.link_color {
  color: rgb(250, 82, 82);
  font-size: 1.3rem;
  border-bottom: 1px solid rgb(248, 97, 97);
  transition: all 0.3s;
  &:hover {
    color: rgb(207, 24, 24);
    text-decoration: none;
  }
}
.whiteLabel {
  margin-left: 4rem;
  position: relative;
  @include respond(phone) {
    margin-left: 1rem;
  }
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-version {
  @include respond(phone) {
    font-size: 1rem !important;
  }
}
.info-box {
  @include respond(phone) {
    position: absolute;
    top: 8rem;
    left: -1rem;
  }
}
