.how-it-works {
  margin-right: 0;
  top: 3.5rem;
}
.how-it-works img {
  max-width: 100%;
}
.how-it-works-background,
.ready-to-sweat {
  width: 100%;
}

.box-1-1 {
  padding: 0 50px 0 50px;
}
.box-1-2 {
  text-align: center;
  margin-top: 50px;
  vertical-align: bottom;
}
.box-1-2 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.image-1-1 {
  float: left;
}
.pure-button {
  background: none;
}
.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
  opacity: 0.5;
}
.button-clicked {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
  opacity: 1;
  background: #efefef;
}
.button img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.headerText {
  position: absolute;
  top: 50%;
  font-size: 3em;
  color: white;
  margin-left: 1.7em;
  font-family: Raleway;
  margin-bottom: unset;
  margin-top: 30px;
}
.readerText {
  font-size: 3em;
  color: white;
}

.subText-boutique {
  margin: 25px;
}

.access-text {
  font-size: 40px;
  font-weight: bolder;
  margin: 0;
}

.box-2-1 {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}

.wired {
  background: #efefef;
  width: 100%;
  height: 100%;
}

.wired-left-top {
  height: 75px;
}

.wired-left-center {
  margin-top: 50px;
  padding: 50px;
}

.wired-hdmi-cable {
  padding: 20px;
}

.wired-right-text {
  font-weight: bolder;
  justify-content: center; /* align horizontal */
  align-items: center;
  margin-left: 0;
}
.right-content {
  margin: auto;
  padding: 50px;
  vertical-align: middle;
}
.hdmi-link {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  color: #a3d2c3;
}

.wireless {
  background: #efefef;
  height: 100%;
  width: 100%;
}
.wireless-right {
  padding: 50px;
}
.stream-image {
  margin-top: 50px;
}
.chromecast {
  background: #efefef;
  width: 100%;
}
.chrome-text {
  font-weight: bolder;
  margin-top: 0;
}
.airplay {
  background: #efefef;
  width: 100%;
}
.airplay-text {
  font-weight: bolder;
}
.tabs > .pure-u-1-2 {
  display: inline-block;
}

.wired,
.wireless,
.chromecast,
.airplay {
  padding-top: 1px;
}
/* Android Section */

.android-section {
  margin: 3%;
}

.access-div img {
  margin-top: 10%;
  width: 25%;
}

/* Roku section */
.roku-section {
  background-color: #efefef;
  padding: 3rem;
}
.roku-text-box {
  text-align: center;
}

.roku-text-box img {
  width: 25%;
  margin-top: 10%;
}
.roku-p {
  margin: 0 auto;
  width: 75%;
}
.roku-tv-img {
  height: auto;
  width: 40vw;
}

/* Creates margin to align title to text body */
.pure-u-md-1-2.pure-u-sm-1.box-1-1 > p.headerText,
.pure-u-md-1-2.pure-u-sm-1.box-2-1 > div > p.access-text {
  margin-left: 10px;
}

@media only screen and (max-width: 800px) {
  .box-1-1 {
    padding: 10px;
  }
  .box-1-2 {
    margin-top: auto;
  }
  .box-2-1 {
    padding: 0 10px 0 10px;
  }
  .wired-left-center {
    padding: 10px;
    margin: 0;
  }
  .right-content {
    padding: 50px 10px;
  }
  .wired-hdmi-cable {
    padding: 0;
  }
  .wireless-right {
    padding: 0;
  }
  .chromecast-text-size-compatible-devices {
    margin: 10px 0;
  }
  .boutique-studios,
  .boutique-sub-section {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
  }
  .subText-boutique {
    bottom: 0;
  }
  .headerText,
  .subText-boutique,
  .readerText {
    font-size: 6vw !important;
  }
  .boutique-text {
    font-size: 4vw !important;
  }

  /* Resizing to stack view  */
  .box-1-1 > img.hdmi-icon.pure-img {
    max-width: 300px;
    max-height: 200px;
    margin: 0 auto;
  }

  .box-2-1 > img.pure-img {
    max-width: 400px;
    max-height: 300px;
    margin: 0 auto;
  }

  .pure-u-md-1-2.pure-u-sm-1.box-1-1,
  .pure-u-md-1-2.pure-u-sm-1.box-2-1 {
    min-width: 550px;
  }

  /* Switch the order of the elements */
  #A-box-2-1.pure-u-md-1-2.pure-u-sm-1.box-2-1 {
    order: 2;
  }
  .ready-to-sweat {
    height: 363px;
    object-fit: cover;
  }
  .how-it-works-background {
    height: 500px;
    object-fit: cover;
  }
  .roku-text-box {
    left: 0 !important;
    margin-bottom: 10%;
  }
  .roku-p {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .pure-u-md-1-2.pure-u-sm-1.box-1-1,
  .pure-u-md-1-2.pure-u-sm-1.box-2-1 {
    min-width: 375px;
    margin: 0 auto;
  }
  .reader-para-text p {
    font-size: 3vw;
  }
}
.access-div {
  text-align: center;
  padding: 1rem;
}
.access-div img {
  margin: 1rem 0;
  width: 30%;
}
.roku-section {
  padding: 2rem 0;
}
.roku-text-box img {
  width: 30%;
  margin-top: 1rem;
}
.roku-tv-img {
  height: auto;
  width: 70%;
}

.subText {
  font-size: 2.5em;
  text-align: center;
  padding-bottom: 15px;
  font-family: Raleway;
  text-transform: uppercase;
}
.subtext {
  margin-bottom: 7vh;
}
.subText1 {
  margin-top: 5%;
  font-size: 2.5em;
}
.box-1-2 {
  font-size: 13px;
}

.boutique-studios {
  display: flex;
  justify-content: space-evenly;
}
.getting-started {
  display: grid;
  grid-template-columns: 2fr 2fr;
}
.access-whenever {
  display: flex;
  flex-direction: column;
  background: #9ac8b9;
  height: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.access-whenever-img {
  margin-right: 10%;
  margin-left: 10%;
  object-fit: contain;
}

.connect-img,
.footer-img {
  margin: auto;
  display: block;
  padding: 10px;
}
.footer-img {
  background: black;
  margin-top: 40px;
}
.footer-details {
  background: black;
  height: 17em !important;
  display: flex;
}
.connect {
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.connect .try-it-for-free-button {
  text-align: center !important;
  padding-bottom: 10px;
  border-color: rgb(163, 210, 195) !important;
  border-radius: unset;
}
.getting-started-img,
.getting-started-img-reverse {
  width: 700px;
  align-self: flex-end;
}
.getting-started-subsection {
  display: flex;
  padding: 2em 4em 4em 4em;
  flex-direction: column;
  justify-content: center;
}

.getting-started-subsection h3 {
  justify-content: left !important;
}
.getting-started-subsection p,
.getting-started-reverse p {
  margin: unset;
  width: 20em;
}
.slider-img {
  width: 20em;
  height: 15em;
}
.fav-slider {
  padding: 2vw;
  overflow-x: hidden;
  position: relative;
  z-index: 2;
}
.slick-dots {
  padding: 25px;
}

.js-home-howitworks-thumbnail {
  height: 18vw;
  z-index: 1;
  position: relative;
  width: 100%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  will-change: transform;
  margin-bottom: 5vh;
}

.js-howitworks-thumbnail-container {
  width: 28.40409vw;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  will-change: transform;
  height: 100%;
}

.thumbnail-img-howitworks {
  height: 100%;
  width: 100%;
}

.reader-para-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
  left: 0;
  right: 0;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.boutique-text {
  font-family: Raleway;
  font-size: 25px;
  padding-top: 15px;
  text-align: center;
}
.subText-boutique {
  text-align: center;
  padding: 1%;
  font-family: Raleway;
}
.getting-started-sub {
  border: solid 1px red;
  display: flex;

  flex-direction: column;
  justify-content: center;
}
.getting-started-reverse {
  display: flex;
  padding-left: 14em;
  flex-direction: column;
  justify-content: center;
}
.getting-started-img-reverse {
  padding: 3em;
}
.getting-started-img {
  padding: 3em;
}
.getting-started-reverse p {
  margin: unset;
}
.getting-started-icon-1,
.getting-started-icon-2,
.getting-started-icon-3 {
  width: 3em;
}
.getting-started-reverse {
  padding: 2em 4em 4em 4em;
}
.getting-started-icon-1 {
  position: absolute;
  top: 10%;
  right: 10em;
}
.getting-started-icon-3 {
  position: absolute;
  top: 10%;
  right: 7em;
}
.getting-started-icon-2 {
  position: absolute;
  top: 5%;
  left: 2em;
}
.icon-text-1 {
  position: absolute;
  top: 12%;
  left: 78%;
  font-size: 1.2em;
  font-weight: bolder;
}

.icon-text-2 {
  position: absolute;
  top: 10%;
  left: 6%;
  font-size: 1.2em;
  font-weight: bolder;
}
.icon-text-3 {
  position: absolute;
  top: 12%;
  left: 83.5%;
  font-size: 1.2em;
  font-weight: bolder;
}
.getting-started-reverse div {
  align-self: flex-end;
}

@media (max-width: 960px) {
  .getting-started {
    display: flex;
    flex-direction: column;
  }
  .getting-started-reverse div,
  .getting-started-reverse-img,
  .getting-started-subsection {
    align-self: center;
  }
  .getting-started-subsection:first-child {
    order: 1;
  }
  .getting-started-subsection:nth-child(2) {
    order: 2;
  }
  .getting-started-reverse {
    order: 4;
  }
  .getting-started-reverse-img {
    order: 3;
  }
  .getting-started-subsection:nth-child(5) {
    order: 5;
  }
  .getting-started-subsection:last-child {
    order: 6;
  }
  .getting-started-img {
    padding: 0;
  }
  .fav-slider .js-font-30-mb {
    height: 10vh !important;
  }
  .getting-started-subsection p,
  .getting-started-reverse p {
    margin: unset;
    width: auto;
  }
  .getting-started-icon-1 {
    top: 0;
    right: 6.5em;
  }

  .getting-started-icon-3 {
    top: 0;
    right: 4em;
  }
}

@media only screen and (max-width: 500px) {
  .js-howitworks-thumbnail-container {
    width: 100%;
  }
  .js-home-howitworks-thumbnail {
    height: 28vh;
  }
  .fav-slider {
    height: 50vh;
  }
  .subtext {
    margin-bottom: 5vh;
  }

  .how-it-works {
    top: 2rem;
  }
}
