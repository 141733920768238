.containerwidth {
  margin: 0 auto 20px auto;
  max-width: 300px;
}

.StripeElement {
  background-color: #eeeeee;
  text-align: center;
}

.margin {
  margin: 0 auto 15px auto;
}

.margin-top {
  margin-top: 5px;
}

.input-width {
  width: 150px;
}

.center-thetext {
  text-align: center;
  margin: 0;
  width: 220px;
}

/* promo section */
.apply-promo-container {
  width: 70%;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
}

.apply-promo-bttn {
  font-size: 0.8rem !important;
}

#input_container {
  position: relative;
  width: 100%;
}
#input {
  height: 20px;
  margin: 0;
  padding-right: 30px;
  width: 100%;
}
#input_img {
  position: absolute;
  bottom: 5.5px;
  right: 5px;
  width: 30px;
  height: 30px;
}

#stripe_img {
  width: 20px;
  height: 20px;
  margin-top: 8px;
}

.IO {
  padding-left: 5px;
  font-size: 15px !important;
  font-family: futura-book, sans-serif;
}

.field {
  grid-column-start: 1;
  grid-row-start: 2;
  border-radius: 5px;
  border-color: #cccccc !important;
  border: 1px solid;
  height: 40px;
  width: 100%;
}

.verify {
  grid-column-start: 2;
  grid-row-start: 2;
  background-color: rgba(163, 210, 195, 0.8);
  padding: 0 10px;
  color: white;
  border-radius: 5px;
  height: 40px;
}

.bad-response {
  color: #b92e36;
  text-align: left;
  font-size: 14px !important;
}
.good-response {
  color: rgba(163, 210, 195, 0.8);
  text-align: left;
  font-size: 14px !important;
}

.incorrect {
  border-color: #b92e36 !important;
  color: #b92e36;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-note {
    width: 300px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .apple-pay-form {
    width: 200px;
    margin: 0 auto 15px auto;
  }
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    height: 46px;
  }
  .apple-pay-button:hover {
    opacity: 0.8;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 6px;
    padding: 10px 16px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 46px;
    max-height: 64px;
    font-size: 18px;
    line-height: 1.3333;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}

.input-card {
  padding: 6px 10px;
  width: 200px;
}
