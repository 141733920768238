.studios-dropDown-container {
  position: relative;
}

.dropDown {
  font-weight: 100;
  border: none;
  outline: none;
  margin: 0;
}

.dropDown-content {
  display: none;
  position: absolute;
  background-color: rgba(252, 254, 253, 0.68);
  min-width: 165px;
  padding: 5px;
  left: -49px;
  overflow: auto;
  max-height: 80vh;
}

.dropDown-content > a {
  transition: 0.25s;
  display: block;
  float: none;
  padding: 3px;
  margin-left: 5px;
  color: #2c2c2c;
}

.dropDown-content > li > a {
  transition: 0.25s;
  display: block;
  float: none;
  padding: 3px;
  margin-left: 5px;
  color: #2c2c2c;
}

.subMenu-content {
  margin-left: 21px;
}

.dropDown-content a:hover {
  color: #2c2c2c;
  transition: 0.25s;
}

.dropDown:hover .dropDown-content {
  display: block;
  list-style: none;
}

.dropDownButton {
  border: none;
  font-size: 1em;
  display: flex;
  font-weight: 100;
  background-color: transparent;
  text-align: left;
  list-style-type: none;
  align-items: center;
  white-space: nowrap;
}

.dropDown:hover .dropDownButton {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  /*Safari*/
  text-decoration: none;
}

.dropDown-content a:hover {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  /*Safari*/
  text-decoration: none;
}

.dropDown-content::-webkit-scrollbar {
  width: 4px;
}

.dropDown-content::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 2px;
}

.dropDown-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
