.Button-createaccount {
  padding: 20px 30px 20px 30px;
  background-color: #9ac8b9;
  border: none;
  font-size: 20px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: sans-serif;
}

.Button-createaccount:hover {
  opacity: 0.7;
}

.Create-accountcenter {
  text-align: center;
  margin: 50px;
}

.Subscription-text {
  text-align: center;
  font-size: 25px;
}

.Subscription-container {
  display: flex;
  width: 700px;
  margin: 0 auto 0 auto;
}

.Monthly-annualbox {
  width: 300px;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 3px solid rgba(188, 190, 192, 0.3);
  margin: 30px auto 40px auto;
}

.Active {
  border: 3px solid #9ac8b9;
}

.Z-index10 {
  z-index: 10;
}

.Plan-font {
  font-size: 15px;
  margin: 0 10px 0 10px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color:rgba(154, 200, 185,0.3);*/
}

.Price-font {
  font-size: 20px;
  font-family: futura-book;
}
.save-font {
  font-size: 20px;
  font-family: futura-book;
  color: #ff6961;
}

.Choose-subscription-box {
  background-color: blue;
  max-width: 800px;
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 0 auto 0 auto;
  position: absolute;
  top: 90px;
}

.Responsive-plan {
  padding: 0 6px;
  float: left;
  width: 50%;
}

.Push-down {
  margin-top: 20%;
}

.product-checkout:hover {
  opacity: 0.7;
}

.Whitelabel-plans {
  width: 50% !important;
  margin-left: 25%;
}

.Whitelabel-oneplan {
  width: 100% !important;
  margin-left: 25%;
}

@media only screen and (max-width: 800px) {
  .Responsive-plan {
    width: 50%;
    margin: 6px 0;
  }
  .Whitelabel-plans {
    width: 100% !important;
    margin-left: 0%;
  }

  .Whitelabel-oneplan {
    width: 100% !important;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 700px) {
  .Responsive-plan {
    width: 100%;
  }
  .Whitelabel-plans {
    width: 100%;
  }
}
