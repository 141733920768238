.explore-studio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explore-studios-the-studio {
  height: 4vw;
  width: 95%;
}
.explore-studios-dtm {
  width: 112px;
}

.explore-studios-hit-house {
  width: 95px;
}
.explore-studios-MNT {
  width: 95px;
}

.explore-studios-twf {
  height: 4.3vw;
  width: 88%;
}
.activeStudioContainer
  > .wistia_responsive_padding
  > .wistia_responsive_wrapper
  > .wistia_embed {
  /* possible styling for video at bottom of page */
}

.slider {
  display: flex;
  flex-direction: row;
  background: #e4e1e1;
  width: 100vw;
  justify-content: center;
  position: relative;
}

.explore-studio-slider {
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 84%;
}

.explore-studio-logo {
  width: 17%;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.explore-studio-logo:hover {
  opacity: 0.5;
  background-color: black;
  border: 1px solid black;
}

#inactive-logo:hover img {
  -webkit-filter: invert(100%);
}

.explore-studios-bari {
  height: 2vw;
  width: 53%;
}
.explore-studios-bkpilates {
  height: 6vw;
  width: 45%;
}

.explore-studios-intensati {
  height: 23%;
  width: 80%;
}
.explore-studios-masterskya {
  height: 2vw;
  width: 77%;
}
.explore-studios-modelfit {
  height: 2vw;
  width: 85%;
}
.explore-studios-skyting {
  height: 4vw;
  width: 78%;
}
.explore-studios-trooper {
  height: 2.4vw;
  width: 75%;
}
.explore-studios-woom {
  height: 2.1vw;
  width: 88%;
}
.explore-studios-xtendbarre {
  height: 2.3vw;
  width: 88%;
}

.explore-studios-solace {
  height: 1.5vw;
  width: 63%;
}

.explore-studios-bodyrok {
  height: 2.3vw;
  width: 88%;
}

.explore-studios-body-space {
  height: 7vw;
  width: 96%;
}

.explore-studios-switch {
  height: 6vw;
  width: 41%;
}

.explore-studios-mindfresh {
  height: 4vw;
  width: 92%;
}

i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 21px;
  margin: 33px;
  cursor: pointer;
}

i:hover {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 21px;
  margin: 33px;
  cursor: pointer;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: absolute;
  right: 0;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 800px) {
  .slider {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .slide-container {
    display: none;
  }
}
