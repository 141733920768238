.js-navbar-container {
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 10;
  background-color: white;
  height: 71px;
  box-shadow: -3px -1px 18px 6px #928f8f;
}

.js-home-hero-cta-button-nav-link:link,
.js-home-hero-cta-button-nav-link:visited {
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  color: #ffffff !important;
  box-shadow: 4px 5px 10px -6px rgba(0, 0, 0, 0.589);
  border: none;
  transition: all 0.3s ease;
  padding: 10px 20px;
  white-space: nowrap;
}

.js-home-hero-cta-button-nav-link:active,
.js-home-hero-cta-button-nav-link:hover {
  transform: translatey(0.1rem);
  background-color: #ef614c;
  box-shadow: 3px 4px 11px -5px #000;
}
@media screen and (orientation: landscape) and (max-width: 800px) {
  .js-navbar-menu-mb {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 17vw;
    z-index: 5;
    background-color: white;
    padding-left: 2vw !important;
    padding-right: 2vw !important;
    padding-top: 2vw !important;
    overflow: hidden;
    overflow-y: scroll;
  }
  .js-navbar-menu-mb li {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .js-navbar-menu-mb a {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .login-btn {
    margin-left: 64px;
    display: flex;
    width: 336px;
    height: 51px;
    justify-content: center;
    align-items: center;
    grid-gap: 32px;
  }
  .js-navbar-menu-mb {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 17vw;
    z-index: 5;
    background-color: white;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 10vw;
    overflow: hidden;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  .js-navbar-logo {
    width: 12.448vw;
    margin-right: 64px;
  }

  .js-navbar-container {
    padding-top: 1.42825vw;
    padding-bottom: 1.42825vw;
    padding-left: 2.92825vw;
    padding-right: 2.92825vw;
  }

  .js-navbar-links > *:not(:last-child) {
    margin-right: 3vw;
  }

  .js-navbar-links {
    display: flex;
    padding: 0px 10px;
    margin-right: 3vw;
    text-align: center;
    align-items: center;
    white-space: nowrap;
  }

  .js-navbar-container-mb {
    display: none;
  }
}

/* Responsive Navbar Menu for Mobile */
@media (max-width: 767px) {
  .js-navbar-signup-btn-mb {
    margin-right: 2vw;
  }
  .js-navbar-mb {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .js-navbar-menu-mb {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 17vw;
    z-index: 5;
    background-color: white;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 10vw;
    overflow: hidden;
    overflow-y: scroll;
    animation: showLinks 0.3s ease-in;
  }

  .js-navbar-container {
    display: none !important;
  }
  .js-navbar-logo {
    width: 35.448vw;
  }
  .js-navbar-menu-mb a {
    display: inline-block;
    padding: 20px;
    font-size: 16px;
    animation: showLinks 0.3s ease-in;
    transition: 0.1s ease-in;
    text-decoration: none;
  }
  .js-navbar-menu-mb li {
    display: block;
    padding: 20px;
    font-size: 16px;
    animation: showLinks 0.3s ease-in;
    transition: 0.1s ease-in;
    cursor: pointer;
  }
  .js-navbar-menu-mb li:hover {
    color: #0056b3;
  }

  .js-navbar-container-mb {
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 2vw;
    padding-right: 2vw;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 5;
    background-color: white;
  }

  .js-navbar-hamburger {
    cursor: pointer;
  }

  .js-navbar-menu-mb > * {
    font-size: 5vw;
    color: black;
  }

  .menu-studio-options a {
    text-decoration: none;
    padding: 5px;
    margin-left: 40px;
    display: inline-block;
    color: #2c2c2c;
    animation: showLinks 0.3s ease-in;
    transition: 0.1s ease-in;
    font-size: 1.3rem;
  }
  .menu-studio-options > ul > ul > a {
    display: block;
    margin-left: 40px;
  }
  .menu-studio-options ul a {
    font-size: 1rem;
  }
  @keyframes showLinks {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

/* Desktop and Tablet Styles */
@media (min-width: 768px) {
  .js-navbar-logo {
    width: 8.4vw;
    margin-right: 64px;
  }

  .js-navbar-container {
    padding-top: 1.42825vw;
    padding-bottom: 1.42825vw;
    padding-left: 2.92825vw;
    padding-right: 2.92825vw;
  }

  .js-navbar-links > *:not(:last-child) {
    margin-right: 3vw;
  }

  .js-navbar-links {
    display: flex;
    padding: 0px 10px;
    margin-right: 3vw;
    text-align: center;
    align-items: center;
    white-space: nowrap;
  }

  .js-navbar-container-mb {
    display: none;
  }

  .login-btn-container {
    display: flex;
    align-items: center;
  }

  .white-label-btn {
    margin-right: 16px;
  }

  .vr {
    border-left: 1px solid #c4c4c4;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 320px) {
  .js-navbar-container-mb {
    width: 100vw;
  }
}

@media (min-width: 375px) {
  .js-navbar-container-mb {
    padding: 0.75rem 1.5rem;
    width: 100vw;
  }
}

@media (min-width: 425px) {
  .js-navbar-container-mb {
    padding-right: 0.75rem;
    width: 100vw;
  }

  .js-navbar-signup-btn-mb {
    align-items: center;
    margin: 0.15rem 0.75rem;
    margin-left: 2.5rem;
    text-align: center;
  }
}
